import { getCookie } from "app/cookie";

export function getPath() {
  if (getCookie("kids")) return "/kids";

  return "";
}

export function getFormattedAddress(place) {
  if (place.name.match(/Airport/i)) {
    return place.name;
  } else {
    return place.formatted_address;
  }
}
