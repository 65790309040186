import { I18n } from "i18n-js";
import { toast } from "react-toastify";

const defaultOption = {
  position: "top-center",
  autoClose: 5000000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const toastInfo = (message, data = {}) => {
  toast.info(message, { ...defaultOption, ...data });
};

export const toastWarning = (message, data = {}) => {
  toast.warning(message, { ...defaultOption, ...data });
};

export const toastSuccess = (message, data = {}) => {
  toast.success(message, { ...defaultOption, ...data });
};

export const toastDark = (message, data = {}) => {
  toast.dark(message, { ...defaultOption, ...data });
};

export const toastError = (message, data = {}) => {
  toast.error(message, { ...defaultOption, ...data });
};

export const toastSuccessMessage = () => {
  toastSuccess(I18n.t("success"));
};

export const toastCreateSuccessMessage = () => {
  toastSuccess(I18n.t("create_success"));
};

export const toastUpdateSuccessMessage = () => {
  toastSuccess(I18n.t("update_success"));
};

export const toastDeleteSuccessMessage = () => {
  toastSuccess(I18n.t("delete_success"));
};

export const toastInvitationSuccessMessage = () => {
  toastSuccess(I18n.t("invite_success"));
};

export const toastLoginSuccessMessage = () => {
  toastSuccess(I18n.t("login_success"));
};

export const toastAcceptSuccessMessage = () => {
  toastSuccess(I18n.t("accept_success"));
};
