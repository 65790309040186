import React, { useRef, useState, useCallback, useEffect } from "react";

import {
  Icon,
  Form,
  Button,
  Checkbox,
  Dropdown,
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
} from "semantic-ui-react";

const periodOptions = [
  { key: 1, text: 1, value: 1 },
  { key: 2, text: 2, value: 2 },
  { key: 3, text: 3, value: 3 },
  { key: 4, text: 4, value: 4 },
  { key: 5, text: 5, value: 5 },
  { key: 6, text: 6, value: 6 },
  { key: 7, text: 7, value: 7 },
  { key: 8, text: 8, value: 8 },
  { key: 9, text: 9, value: 9 },
  { key: 10, text: 10, value: 10 },
  { key: 12, text: 12, value: 12 },
  { key: 13, text: 13, value: 13 },
  { key: 14, text: 14, value: 14 },
  { key: 15, text: 15, value: 15 },
  { key: 16, text: 16, value: 16 },
  { key: 17, text: 17, value: 17 },
  { key: 18, text: 18, value: 18 },
  { key: 19, text: 19, value: 19 },
  { key: 20, text: 20, value: 20 },
  { key: 21, text: 21, value: 21 },
  { key: 22, text: 22, value: 22 },
  { key: 23, text: 23, value: 23 },
  { key: 24, text: 24, value: 24 },
];

const ManageHours = ({ trip, onSave, onClose }) => {
  const [hours, setHours] = useState(1);
  const [chargeNeeded, setChargeNeeded] = useState(false);

  const [validations, setValidations] = useState({});

  return (
    <Modal size={"tiny"} open onClose={onClose}>
      <ModalHeader>Add Hours</ModalHeader>
      <ModalContent>
        <Form loading={false} autoComplete="off">
          <div
            style={{
              display: "flex",
              // justifyContent: "flex-end",
              marginBottom: 16,
              fontSize: "1.8em",
              height: 49,
              alignItems: "center",
            }}
          >
            <div>
              <Icon name="hourglass one" style={{ fontSize: "0.7em" }} />
              <Dropdown
                inline
                scrolling
                value={hours}
                defaultValue={hours}
                options={periodOptions}
                onChange={(event, data) => {
                  setHours(data.value);
                }}
                style={{ fontSize: "0.8em", marginRight: 12 }}
              ></Dropdown>
            </div>

            <div>
              <Dropdown
                inline
                scrolling
                value={"hours"}
                defaultValue={"hours"}
                style={{ fontSize: "0.8em", marginRight: 12 }}
                options={[{ key: "hours", text: "hours", value: "hours" }]}
              />
            </div>
          </div>

          <Checkbox
            checked={chargeNeeded}
            style={{ fontSize: "1.2em" }}
            label="Pre authorize charge for this request"
            onChange={(e, data) => setChargeNeeded(data.checked)}
          />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="black"
          onClick={() =>
            onSave({
              hours,
              charge_needed: chargeNeeded,
            })
          }
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default ManageHours;
