import { I18n } from "i18n-js";

import { toastWarning, toastError } from "./toast";

import config from "app/config";

export const handleApiError = (error, onValidation) => {
  if (config.debug) {
    console.log(error);
  }

  if (
    error &&
    error.response &&
    (error.response.status === 401 ||
      error.response.status === 403 ||
      error.response.status === 404)
  ) {
    toastError(error.response.data.errors.base);

    return;
  }

  if (error.response && error.response.data && error.response.data.errors) {
    const { errors } = error.response.data;

    console.log(error.response);

    if (errors.base) {
      toastError(errors.base);
      // onErrors([{ type: "error", content: errors.base }]);
    } else {
      onValidation(errors);
    }
  } else {
    toastError(error.message);
  }
};

export function handleAxiosError(error) {
  const { response } = error;

  if (!response) {
    handleServerError();
    throw { handled: true };
  }

  if (response.status === 403) {
    handleUnauthorized(response.data);
    response.handled = true;
  } else if (response.status === 404) {
    handleNotFound();
    response.handled = true;
  }

  throw response;
}

export function handleBaseResponseErrors(response) {
  const { status, data } = response;

  if (!data) {
    console.log(response);
    return;
  }

  const { errors } = data;

  if (status === 422 && errors.base) {
    errors.base.forEach((error) => {
      // toastWarning(error, { position: "top-center" });
    });
  }
}

export function handleMeResponse(response) {
  if (response.handled) return;

  handleBaseResponseErrors(response);

  if (response.status === 422) {
    return response.data.errors;
  } else if (response.status === 401) {
    // toastError(response.data.errors.base[0], { position: "top-center" });
  } else {
    console.log(response);
  }
}

export function handleActionResponse(response) {
  if (response.handled) return;

  handleBaseResponseErrors(response);

  if (response.status === 422) {
    return response.data.errors;
  } else if (response.status === 401) {
    // toastError(response.data.errors.base[0], { position: "top-center" });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  } else {
    console.log(response);
  }
}

function handleServerError() {
  // toastError(I18n.t("server_error"));
  // toastError("Server Error");
}

function handleUnauthorized(data) {
  if (data && data.errors && data.errors.base) {
    // toastWarning(data.errors.base[0]);

    return;
  }
  // toastError("Unauthorized");
}

function handleNotFound() {
  toastError("Not Found");
}
