import React, { useState, useEffect, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import {
  Menu,
  Icon,
  Header,
  Input,
  MenuItem,
  MenuMenu,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownDivider,
  DropdownHeader,
  Container,
  Pagination,
} from "semantic-ui-react";

import Trip from "app/pages/trips/trip";
import Loading from "app/components/loading";

import { handleApiError } from "app/utils/errors";
import { useGlobal } from "app/services/global/context";

const Trips = () => {
  const navigate = useNavigate();

  const { user, identity, onLogin, onFetchTrips } = useGlobal();

  const [trips, setTrips] = useState([]);
  const [records, setRecords] = useState({});

  const [activeItem, setActiveItem] = useState("booked");

  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [validations, setValidations] = useState({});

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [filters, setFilters] = useState({
    sort: { created_at: "asc" },
    search: {},
    filter: { state: ["pending", "booked", "confirmed"] },
  });

  const [searchFocused, setSearchFocused] = useState(false);
  const [sortBy, setSortBy] = useState("created_at");
  const [pages, setPages] = useState({
    current_page: 1,
    next_page: null,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  });

  const options = [
    {
      key: "created_at",
      text: "Created Date",
      value: "created_at",
    },
    {
      key: "pickup_datetime",
      text: "Pickup Date",
      value: "pickup_datetime",
    },
  ];

  const showPagination = pages.total_count > perPage;

  console.log(user);

  const handleActiveItemChange = useCallback((event, data) => {
    setActiveItem(data.name);

    if (data.name === "booked") {
      setFilters((filters) => ({
        ...filters,
        filter: { state: ["pending", "booked", "confirmed"] },
      }));
    } else if (data.name === "completed") {
      setFilters((filters) => ({
        ...filters,
        filter: { state: ["finished"] },
      }));
    } else {
      setFilters((filters) => ({
        ...filters,
        filter: { state: ["declined", "cancelled"] },
      }));
    }
  }, []);

  const fetchTrips = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onFetchTrips({
          page,
          filters,
          per_page: perPage,
        });

        const { meta, trips } = response.data;

        setPages(meta.pages);
        setTrips(trips);

        const records = trips.reduce((hash, trip) => {
          hash[trip.number] = trip;

          return hash;
        }, {});

        setRecords(records);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
        setInitialized(true);
      }
    };

    requestAsync();
  }, [page, perPage, filters]);

  useEffect(() => {
    fetchTrips();
  }, [page, filters]);

  useEffect(() => {
    setTrips(Object.values(records));
  }, [records]);

  useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      sort: { [sortBy]: "asc" },
    }));
  }, [sortBy]);

  if (!user) {
    return (
      <Container>
        <Header as="h1">Trips</Header>
      </Container>
    );
  }

  const { account_driver, account_owner, super_admin } = user;

  if (!initialized) {
    return (
      <Container>
        <Header as="h1">Trips</Header>
        <Loading />
      </Container>
    );
  }

  // if (trips.length === 0) {
  //   return (
  //     <Container style={{ marginTop: 100 }}>
  //       <Header as="h1">Trips</Header>

  //     </Container>
  //   );
  // }

  return (
    <Container>
      <Header as="h1">Trips</Header>

      <Menu pointing secondary size="huge" style={{ marginBottom: 20 }}>
        <MenuItem
          name="booked"
          active={activeItem === "booked"}
          onClick={handleActiveItemChange}
        />
        <MenuItem
          name="completed"
          active={activeItem === "completed"}
          onClick={handleActiveItemChange}
        />

        <MenuItem
          name="cancelled"
          active={activeItem === "cancelled"}
          onClick={handleActiveItemChange}
        />

        <MenuMenu position="right">
          <Dropdown text="Filter" multiple icon="filter" style={{ padding: 0 }}>
            <DropdownMenu open={searchFocused}>
              <Input
                icon="search"
                iconPosition="left"
                className="search"
                placeholder="Search"
                onBlur={() => setSearchFocused(false)}
                onFocus={() => setSearchFocused(true)}
                onChange={(event, data) => {
                  setFilters((filters) => ({
                    ...filters,
                    search: { number: data.value },
                  }));
                }}
              />
              <DropdownDivider />
              <DropdownHeader icon="sort" content="Sort By" />
              <DropdownMenu scrolling>
                {options.map((option) => (
                  <DropdownItem
                    active={sortBy === option.value}
                    key={option.value}
                    {...option}
                    onClick={(event, data) => setSortBy(data.value)}
                  />
                ))}
              </DropdownMenu>
            </DropdownMenu>
          </Dropdown>
        </MenuMenu>
      </Menu>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "1.2em",
            fontWeight: 700,
            color: "rgba(0, 0, 0, .95)",
            marginBottom: 10,
            background: "#e8e8e8",
            paddingLeft: 8,
            paddingTop: 4,
            paddingRight: 8,
            paddingBottom: 4,
            borderRadius: "4px",
          }}
        >
          <div style={{ width: "10%" }}>ID</div>
          <div style={{ width: "20%", textAlign: "center" }}>Payment</div>
          <div style={{ width: "30%", textAlign: "center" }}>Pickup</div>
          <div style={{ width: "20%", textAlign: "center" }}>Date</div>
          <div style={{ width: "20%", textAlign: "right" }}>Status</div>
        </div>
        {trips.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "24px",
              alignItems: "center",
              height: "300px",
            }}
          >
            No Data
          </div>
        ) : (
          trips.map((trip) => {
            return <Trip trip={trip} user={user} />;
          })
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 60,
          marginBottom: 60,
        }}
      >
        {showPagination && (
          <Pagination
            pointing
            secondary
            size="huge"
            activePage={pages.current_page}
            defaultActivePage={pages.current_page}
            totalPages={pages.total_pages}
            onPageChange={(event, { activePage }) => {
              setPage(activePage);
            }}
          />
        )}
      </div>
    </Container>
  );
};

export default Trips;
