import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import me from "./me";
import main from "./main";
import accounts from "./accounts";
import globals from "./globals";

import csrf_token from "./csrf_token";
import help_pages from "./help_pages";

import settings from "./settings";
import credentials from "./credentials";

const reducer = combineReducers({
  me,
  main,
  accounts,
  globals,

  csrf_token,
  help_pages,

  settings,
  credentials,
});

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
