import React, { useState, useEffect } from "react";

import Lottie from "react-lottie";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Loading from "app/components/loading";

import { unlockEmail } from "../../store/me";

export default function UnlockEmail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const search = location.search;
  const params = new URLSearchParams(search);
  const unlock_token = params.get("unlock_token");

  const { user, login_needed } = useSelector((state) => state.me);

  useEffect(() => {
    if (unlock_token) {
      dispatch(unlockEmail({ unlock_token }));
    } else {
      navigate("/");
    }
  }, [unlock_token]);

  useEffect(() => {
    if (login_needed) {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  }, [login_needed]);

  return <Loading />;
}
