import React from "react";

import { Container } from "semantic-ui-react";

import TopBar from "./TopBar";

export default function NavBar(props) {
  return (
    <Container>
      <TopBar />
    </Container>
  );
}
