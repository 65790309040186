import React from "react";

import Lottie from "react-lottie";

import * as loading from "app/assets/lottie/loading-luxti.json";

function Loading({ height = 220, width = 220, style }) {
  return (
    <Lottie
      speed={1}
      isClickToPauseDisabled
      style={{
        margin: "auto auto",
        ...style,
      }}
      options={{
        loop: true,
        autoplay: true,
        animationData: loading.default,
      }}
      height={height}
      width={width}
      isStopped={false}
      isPaused={false}
    />
  );
}

export default Loading;
