import React, { useState, useEffect, useCallback } from "react";

import Lottie from "react-lottie";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Header,
  Container,
  Message,
  GridColumn,
  Grid,
  GridRow,
} from "semantic-ui-react";

import { handleApiError } from "app/utils/errors";
import { useGlobal } from "app/services/global/context";

import Loading from "app/components/loading";
import booked from "app/assets/lottie/booked.json";

const TripBooked = () => {
  const navigate = useNavigate();
  const { trip_id, charge_id } = useParams();

  const { loaded, authenticated, onGetTrip } = useGlobal();

  const [trip, setTrip] = useState(null);
  const [pickupLocation, setPickupLocation] = useState();

  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [validations, setValidations] = useState({});

  const getTrip = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onGetTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);

        setInitialized(true);
        setLoading(false);
      } catch (e) {
        handleApiError(e);

        navigate("/");
      }
    };

    requestAsync();
  }, [trip_id]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    getTrip();
  }, [authenticated]);

  useEffect(() => {
    if (!trip) {
      return;
    }

    const { locations } = trip;

    const pickup = locations.find((l) => l.type === "pickup");

    setPickupLocation(pickup);
  }, [trip]);

  if (loaded && !authenticated) {
    navigate("/");

    return;
  }

  if (!trip) {
    return (
      <Container style={{ marginTop: 100 }}>
        <Header as="h1">Trip</Header>
        <Loading />
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: 100, marginBottom: 100 }}>
      <Header as="h1">Trip payment received</Header>

      <Message style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ fontWeight: "bold", fontSize: "1.3em" }}>
          {pickupLocation && pickupLocation.name}
        </div>
        <div style={{ fontWeight: "bold", fontSize: "1.5em" }}>
          {trip.amount_in_currency}
        </div>
      </Message>

      <Grid>
        <GridRow>
          <GridColumn>
            <Lottie
              width={340}
              height={340}
              options={{
                loop: false,
                autoplay: true,
                animationData: booked,
              }}
            />

            <Button
              inline
              content="Continue"
              secondary
              size="big"
              floated="right"
              onClick={() => navigate("/")}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </Container>
  );
};

export default TripBooked;
