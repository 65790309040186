import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";

import Lottie from "react-lottie";
import OtpInput from "react-otp-input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Icon,
  Header,
  Image,
  Message,
  Segment,
  Input,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

// import { login, logout, getSsoDetails, resetLoginNeeded } from "app/store/me";

import SocialLogin from "app/components/social_login";

import * as signup from "app/assets/lottie/signup-car.json";

import { toastSuccess } from "app/utils/toast";
import { handleApiError } from "app/utils/errors";

import { useGlobal } from "app/services/global/context";

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    form,
    identity,
    pendingTrip,
    onIdentity,
    onLogin,
    onResetForm,
    onPendingTrip,
    onCreateTrip,
    onVerifyToken,
    onAuthenticated,
    onFetchPassengers,
    onCreateTripPassengers,
  } = useGlobal();

  const { login } = identity;

  // const { csrf_token } = useSelector((state) => state.csrf_token);
  // const { user, sso, logging_in, logged_in, validations } = useSelector(
  //   (state) => state.me
  // );
  // const { state = {} } = location;
  // const { robot } = state;
  // const [loginWithQrCode, setLoginWithQrCode] = useState(false);

  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(60);
  const [validations, setValidations] = useState({});

  const handleVerifyClick = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onVerifyToken({
          auth: {
            token,
            login,
          },
        });

        const { user } = response.data;

        const jwtToken = response.headers["x-auth-token"];

        onAuthenticated(user, jwtToken);

        if (pendingTrip) {
          const response = await onCreateTrip({
            trip: form,
          });

          const { trip } = response.data;

          const { id, charges } = trip;

          const {
            data: { passengers = [] },
          } = await onFetchPassengers();

          if (passengers.length > 0) {
            await onCreateTripPassengers(id, {
              trip: { passenger_ids: [passengers[0].id] },
            });
          }

          onResetForm();
          onPendingTrip(false);

          navigate(`/trips/${id}/charges/${charges[0].id}/payment`);
        } else {
          toastSuccess(`Login ${login} has been verified.`);

          navigate("/");
        }
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, [token, login, form, pendingTrip]);

  const handleResetClick = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        await onLogin({
          session: {
            login,
          },
        });

        setToken();
        setCounter(60);

        toastSuccess(
          `Verification code has been sent to your provided ${login}.`
        );
      } catch (e) {
        console.log(e);
        if (e.data) {
          const { errors } = e.data;
          setValidations(errors);
        } else {
          console.log(e);
        }
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, [login]);

  useEffect(() => {
    if (!login) {
      navigate("/login");
    }
  }, [login]);

  useEffect(() => {
    if (counter === 0) {
      return;
    }

    const timeout = setTimeout(() => setCounter(counter - 1), 1000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [counter]);

  // function loginUser() {
  //   dispatch(login(data));

  //   // if (data.email.match(/@/)) {
  //   //   dispatch(login(data));
  //   // } else {
  //   //   dispatch(loginKid({ username: data.email, password: data.password }));
  //   // }
  // }

  // useEffect(() => {
  //   dispatch(resetLoginNeeded());
  // }, []);

  // useEffect(() => {
  //   if (robot) loginUser();
  // }, [robot]);

  // useEffect(() => {
  //   if (logged_in) {
  //     navigate("/");
  //   }
  // }, [logged_in]);

  return (
    <Grid textAlign="center" verticalAlign="middle" style={{ padding: 0 }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Lottie
          style={{
            marginBottom: -44,
          }}
          width={240}
          height={240}
          options={{
            loop: false,
            autoplay: true,
            animationData: signup.default,
          }}
          isStopped={false}
          isPaused={false}
        />

        <Form size="large" loading={loading} autoComplete="off">
          <Segment stacked>
            <div style={{ marginBottom: 24 }}>
              <OtpInput
                value={token}
                onChange={setToken}
                numInputs={6}
                shouldAutoFocus
                inputStyle={{
                  width: "2.4em",
                  height: "2.4em",
                  fontSize: "1.4em",
                  padding: 0,
                  fontWeight: "bold",
                }}
                containerStyle={{
                  justifyContent: "space-between",
                }}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            <div style={{ marginBottom: 48, fontSize: "1.2em" }}>
              Verification code sent to{" "}
              <Link
                to="/login"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {identity.login}
              </Link>
              . You will be able to resend the code after {counter}s
            </div>

            {counter === 0 && (
              <Button
                fluid
                basic
                type="submit"
                color="pink"
                size="medium"
                style={{ color: "#6f3d74" }}
                disabled={loading}
                onClick={handleResetClick}
              >
                RESEND
              </Button>
            )}

            {counter !== 0 && (
              <Button
                fluid
                // compact
                type="submit"
                color="black"
                size="large"
                disabled={loading}
                onClick={handleVerifyClick}
              >
                Verify
              </Button>
            )}
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

// margin-bottom: 16px;
//     color: #6f3d74;
//     font-weight: bold;
//     text-decoration: underline;
