import axios from "axios";
import { I18n } from "i18n-js";
import moment from "moment-timezone";

import { getCookie, setCookie, removeCookie } from "app/cookie";

export function setLocale(locale) {
  I18n.locale = locale;
  moment.locale(locale);
  setCookie("locale", locale);
  axios.defaults.headers.common["Accept-Language"] = locale;
}

export function setTimeZone(timezone) {
  // moment.tz.setDefault(timezone);
  axios.defaults.headers.common["Accept-Timzone"] = timezone;
}

// const setCurrency = (currency) => {
//   setCookie("currency", currency);
//   axios.defaults.headers.common["Accept-Currency"] = currency;
// };

// const setCity = (city) => {
//   setCookie("city", city);
//   axios.defaults.headers.common["Accept-City"] = city;
// };

export function setCSRFToken(csrf_token) {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrf_token;
}
