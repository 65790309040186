import React, { useState, useContext, createContext, useEffect } from "react";

import axios from "axios";
import { DateTime } from "luxon";

import { getCookie, setCookie, removeCookie } from "app/cookie";

import {
  getMeRequest,
  fetchCardsRequest,
  fetchPublicKeysRequest,
  createSessionRequest,
  createRegisterRequest,
  createVerifyTokenRequest,
  getTripRequest,
  fetchTripsRequest,
  createTripRequest,
  updateTripRequest,
  confirmTripRequest,
  declineTripRequest,
  cancelTripRequest,
  estimateTripFeeRequest,
  estimateTripRequest,
  getChargeRequest,
  updateChargeRequest,
  createTripPaymentIntentRequest,
  createSetupIntentRequest,
  fetchDriversRequest,
  fetchPassengersRequest,
  createPassengerRequest,
  updatePassengerRequest,
  createChargePaymentIntentRequest,
  createTripDriversRequest,
  updateAdminTripRequest,
  startTripRequest,
  arriveTripRequest,
  pickupTripRequest,
  waitTripRequest,
  finishTripRequest,
  createTripPassengersRequest,
  getHourRequest,
  createHourRequest,
  getLocationRequest,
  createLocationRequest,
  prepareProcessPaymentRequest,
  settleProcessPaymentRequest,
} from "./service";

export const GlobalContext = createContext();
export const useGlobal = () => useContext(GlobalContext);

const dt = DateTime.now().setZone("America/Los_Angeles");

const klassOptions = [
  {
    key: "sedan_business_klass",
    text: "Business Class",
    value: "sedan_business_klass",
    options: [{ luggages: 2, passengers: 3 }],
  },
  {
    key: "suv_klass",
    text: "SUV",
    value: "suv_klass",
    options: [
      { luggages: 5, passengers: 5 },
      { luggages: 6, passengers: 6 },
    ],
  },
  {
    key: "sedan_first_klass",
    text: "First Class",
    value: "sedan_first_klass",
    options: [{ luggages: 2, passengers: 3 }],
  },
  {
    key: "sprinter_klass",
    text: "Sprinter",
    value: "sprinter_klass",
    options: [
      { luggages: 14, passengers: 14 },
      { luggages: 20, passengers: 20 },
    ],
  },
];

const FORM_DATA = {
  type: "one_time",
  klass: null,
  distance: null,
  number_of_passengers: null,
  number_of_luggages: null,
  pickup_date: null,
  pickup_time: null,
  locations_attributes: [],
  trip_hours_attributes: [
    {
      days: 1,
      hours: 2,
    },
  ],
};

export const GlobalContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [identity, setIdentity] = useState({
    type: "email",
    login: "",
  });
  const [keys, setKeys] = useState({
    stripe_publishable_key: null,
  });
  const [stripe, setStripe] = useState(null);

  const [loaded, setLoaded] = useState(false);
  const [jwtToken, setJwtToken] = useState();
  const [authenticated, setAutheticated] = useState(false);

  const [form, setForm] = useState(FORM_DATA);
  const [dateToday, setDateToday] = useState();
  const [pendingTrip, setPendingTrip] = useState(false);
  const [klassDisplay, setKlassDisplay] = useState(null);
  const [passengerOptions, setPassengerOptions] = useState([]);

  // State management
  const onLoaded = (data) => setLoaded(data);
  const onJwtToken = (data) => setJwtToken(data);
  const onIdentity = (data) => setIdentity(data);
  const onStripe = (data) => setStripe(data);

  const onForm = (data) => setForm(data);
  const onResetForm = () => setForm(FORM_DATA);
  const onPendingTrip = (data) => setPendingTrip(data);
  const onKlassDisplay = (data) => setKlassDisplay(data);
  const onPassengerOptions = (data) => setPassengerOptions(data);

  const onLogout = () => {
    setUser();
    setJwtToken();
    removeCookie("token");
    setAutheticated(false);
  };
  const onAuthenticated = (user, token) => {
    setUser(user);
    setJwtToken(token);
    setCookie("token", token);
    onAxiosAuthorization(token);
    setAutheticated(true);
  };

  const onKeys = (data) => setKeys(data);

  const onAxiosAuthorization = (token) => {
    axios.defaults.headers.common["Authorization"] = token;
  };

  // Request public resources
  const onFetchPublicKeys = (data) => fetchPublicKeysRequest(data);

  // Request management
  const onGetMe = (data) => getMeRequest(data);
  const onLogin = (data) => createSessionRequest(data);
  const onRegister = (data) => createRegisterRequest(data);
  const onVerifyToken = (data) => createVerifyTokenRequest(data);

  const onFetchCards = (data) => fetchCardsRequest(data);

  const onGetTrip = (id) => getTripRequest(id);
  const onFetchTrips = (data) => fetchTripsRequest(data);
  const onCreateTrip = (data) => createTripRequest(data);
  const onUpdateTrip = (id, data) => updateTripRequest(id, data);

  const onGetCharge = (id) => getChargeRequest(id);
  const onUpdateCharge = (id, data) => updateChargeRequest(id, data);

  const onConfirmTrip = (data) => confirmTripRequest(data);
  const onDeclineTrip = (data) => declineTripRequest(data);
  const onCancelTrip = (data) => cancelTripRequest(data);
  const onEstimateTrip = (data) => estimateTripFeeRequest(data);
  const onEstimateDistanceTrip = (data) => estimateTripRequest(data);
  const onCreateTripPaymentIntent = (data) =>
    createTripPaymentIntentRequest(data);
  const onCreateChargePaymentIntent = (data) =>
    createChargePaymentIntentRequest(data);
  const onCreateTripDrivers = (id, data) => createTripDriversRequest(id, data);
  const onCreateTripPassengers = (id, data) =>
    createTripPassengersRequest(id, data);

  const onCreateSetupIntent = (data) => createSetupIntentRequest(data);

  const onFetchPassengers = (data) => fetchPassengersRequest(data);
  const onCreatePassenger = (data) => createPassengerRequest(data);
  const onUpdatePassenger = (id, data) => updatePassengerRequest(id, data);

  const onFetchDrivers = (data) => fetchDriversRequest(data);

  const onUpdateAdminTrip = (id, data) => updateAdminTripRequest(id, data);
  const onGetHourRequest = (id) => getHourRequest(id);
  const onCreateHourRequest = (data) => createHourRequest(data);
  const onGetLocationRequest = (id) => getLocationRequest(id);
  const onCreateLocationRequest = (data) => createLocationRequest(data);

  // Driver requests
  const onStartedTrip = (id, data) => startTripRequest(id, data);
  const onArrivedTrip = (id, data) => arriveTripRequest(id, data);
  const onPickUpCustomerTrip = (id, data) => pickupTripRequest(id, data);
  const onWaitingTrip = (id, data) => waitTripRequest(id, data);
  const onFinishTrip = (id, data) => finishTripRequest(id, data);

  const onPrepareProcessPayment = (id, data) =>
    prepareProcessPaymentRequest(id, data);
  const onSettleProcessPayment = (id, data) =>
    settleProcessPaymentRequest(id, data);

  useEffect(() => {
    const next15Minutes = dt.plus({ minutes: 75 });
    const parsedMinutes = next15Minutes.minute;
    const shiftedMinutes = Math.floor(parsedMinutes / 15) * 15;
    const local = next15Minutes.set({ minute: shiftedMinutes });

    onForm((form) => ({
      ...form,
      pickup_date: local.toLocaleString({
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      pickup_time: local.toLocaleString({ hour: "numeric", minute: "2-digit" }),
    }));

    setDateToday(local);
  }, [dt]);

  useEffect(() => {
    if (klassOptions.length === 0) {
      return;
    }

    const klassOption = klassOptions[0];
    const { options } = klassOption;
    const option = options[0];

    onForm((form) => ({
      ...form,
      klass: klassOption.key,
      number_of_luggages: option.luggages,
      number_of_passengers: option.passengers,
    }));

    onKlassDisplay(klassOption.text);
    setPassengerOptions(
      options.reduce((array, option) => {
        array.push({
          key: option.passengers,
          text: option.passengers,
          value: option.passengers,
        });

        return array;
      }, [])
    );
  }, [klassOptions]);

  return (
    <GlobalContext.Provider
      value={{
        loaded,

        user,
        identity,
        authenticated,

        keys,
        onKeys,
        onFetchPublicKeys,

        stripe,
        onStripe,

        dateToday,

        form,
        onForm,
        onResetForm,
        pendingTrip,
        onPendingTrip,
        klassOptions,
        klassDisplay,
        onKlassDisplay,
        passengerOptions,
        onPassengerOptions,

        onLoaded,
        onJwtToken,
        onIdentity,
        onLogout,
        onAuthenticated,

        onLogin,
        onRegister,
        onVerifyToken,
        onAxiosAuthorization,

        onGetMe,

        onFetchCards,

        onGetTrip,
        onFetchTrips,
        onCreateTrip,
        onUpdateTrip,

        onGetCharge,
        onUpdateCharge,

        onConfirmTrip,
        onDeclineTrip,
        onCancelTrip,

        onEstimateTrip,
        onEstimateDistanceTrip,

        onCreateTripPaymentIntent,
        onCreateSetupIntent,

        onCreateChargePaymentIntent,

        onFetchPassengers,
        onCreatePassenger,
        onUpdatePassenger,

        onFetchDrivers,

        onUpdateAdminTrip,

        // Driver requests
        onStartedTrip,
        onArrivedTrip,
        onPickUpCustomerTrip,
        onWaitingTrip,
        onFinishTrip,

        onCreateTripPassengers,

        onGetHourRequest,
        onCreateHourRequest,
        onGetLocationRequest,
        onCreateLocationRequest,

        onPrepareProcessPayment,
        onSettleProcessPayment,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
