const config = {
  env: process.env.NODE_ENV,
  debug: process.env.NODE_ENV === "development",
  url: process.env.REACT_APP_API_URL,
  app_url: process.env.REACT_APP_APP_URL,
  cable_url: process.env.REACT_APP_WS_URL,
  cookie_domain: process.env.REACT_APP_COOKIE_DOMAIN,
};

export default config;
