import React, { useContext, createContext } from "react";

import { createTripRequest } from "./service";

export const ValidationsContext = createContext();
export const useValidations = () => useContext(ValidationsContext);

export const ValidationsContextProvider = ({ children }) => {
  const onValidateCreateTrip = (data) => createTripRequest(data);

  return (
    <ValidationsContext.Provider
      value={{
        onValidateCreateTrip,
      }}
    >
      {children}
    </ValidationsContext.Provider>
  );
};
