import React from "react";

import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import { APIProvider } from "@vis.gl/react-google-maps";

import store from "app/store";
import config from "app/config";
import Routes from "app/routes";
import NavBar from "app/pages/nav_bar";
import Footer from "app/pages/footer";

import { AppContainer } from "app/styles";
import { ActionCableProvider } from "app/cable";
import VideoPlayerModal from "app/components/VideoPlayerModal";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faAppleAlt,
  faIceCream,
  faCookie,
} from "@fortawesome/free-solid-svg-icons";

import "react-toastify/dist/ReactToastify.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import "app/assets/styles/index.css";

import { GlobalContextProvider } from "app/services/global/context";

library.add(fab, faAppleAlt, faIceCream, faCookie);

export default function App() {
  return (
    <GlobalContextProvider>
      <ActionCableProvider url={config.cable_url}>
        <AppContainer>
          <Provider store={store}>
            <CookiesProvider>
              <APIProvider apiKey={"AIzaSyBlemv-pCwDoHlTVR3kSuDB4n3wlQsbBl4"}>
                <Router>
                  <NavBar />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Routes />
                    <Footer />
                  </div>

                  <ToastContainer />
                  <VideoPlayerModal />
                </Router>
              </APIProvider>
            </CookiesProvider>
          </Provider>
        </AppContainer>
      </ActionCableProvider>
    </GlobalContextProvider>
  );
}

// After App container
// <StripeProvider apiKey="null">
// </StripeProvider>
