import React, { useState, useCallback, useEffect } from "react";

import { Button, Header } from "semantic-ui-react";

const Request = ({ request, index, onGetRequest, onSuccess }) => {
  const [timer, setTimer] = useState();
  const [record, setRecord] = useState(request);

  const extraStyles =
    index > 0
      ? {
          borderTop: "1px solid #c1c1c1",
          marginTop: "10px",
          paddingTop: "10px",
          alignItems: "center",
        }
      : {};

  const getRequest = useCallback((id) => {
    const requestAsync = async () => {
      try {
        const response = await onGetRequest(id);

        const { location_request } = response.data;

        setRecord(location_request);
      } catch (e) {}
    };

    requestAsync();
  }, []);

  useEffect(() => {
    if (record.handled) {
      onSuccess();
    } else {
      const timer = setTimeout(() => {
        getRequest(record.id);
      }, 2000);

      setTimer(timer);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [record]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...extraStyles,
      }}
    >
      <Header
        as="h3"
        style={{ marginTop: 0, marginBottom: 0, marginRight: 20 }}
        content={record.title}
        subheader={record.description}
      />

      {record.state === "pending" && (
        <Button compact color="gray">
          PENDING
        </Button>
      )}

      {record.state === "in_process" && (
        <Button compact circular loading icon="loading" />
      )}

      {record.state === "succeeded" && (
        <Button compact color="teal">
          SUCCESS
        </Button>
      )}

      {record.state === "action_needed" && (
        <Button compact color="pink">
          CUSTOMER MUST FINALIZE PAYMENT. STAND BY.
        </Button>
      )}
    </div>
  );
};

export default Request;
