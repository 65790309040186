import React from "react";

import { Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const PAYMENT_STATE = {
  payment_pending: "Pending",
  payment_authorized: "Authorized",
  payment_needs_action: "Action Needed",
  payment_received: "Paid",
  payment_refunded: "Refunded",
  payment_declined: "Declined",
  payment_stopped: "Stopped",
};

const Trip = ({ trip }) => {
  const navigate = useNavigate();

  const {
    id,
    state,
    number,
    drivers,
    locations,
    pickup_date,
    pickup_time,
    payment_state,
  } = trip;

  const pickupLocation = locations.find(
    (location) => location.type === "pickup"
  );

  return (
    <div
      style={{
        borderBottom: "1px solid rgb(220 220 220)",
        paddingBottom: 10,
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "1.2em",
          paddingLeft: 8,
          paddingTop: 4,
          paddingRight: 8,
          paddingBottom: 4,
          fontWeight: 700,
          color: "rgb(56 67 75 / 95%)",
          marginBottom: 10,
        }}
      >
        <div style={{ width: "10%", wordBreak: "break-all" }}>
          <div>{number}</div>
        </div>

        <div
          style={{
            width: "20%",
            textAlign: "center",
          }}
        >
          <div>{PAYMENT_STATE[payment_state]}</div>
        </div>

        <div
          style={{
            width: "30%",
            textAlign: "center",
            wordBreak: "break-all",
            paddingLeft: "2%",
            paddingRight: "2%",
          }}
        >
          {pickupLocation && pickupLocation.name}
        </div>
        <div style={{ width: "20%", textAlign: "center" }}>
          {pickup_date}, {pickup_time}
        </div>
        <div
          style={{
            width: "20%",
            fontSize: "0.9em",
            textAlign: "right",
            textTransform: "uppercase",
          }}
        >
          {state}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row-reverse",
          alignItems: "center",
          paddingLeft: 4,
        }}
      >
        <Button compact color="black" onClick={() => navigate(`/trips/${id}`)}>
          View
        </Button>

        {drivers.map((driver) => {
          return (
            <div
              style={{
                fontSize: "1.2em",
                borderBottom: "1px solid gray",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Driver</span> - {driver.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Trip;
