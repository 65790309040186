import React, { useEffect } from "react";

import { useStopwatch } from "react-timer-hook";

const WaitCounter = ({ wait_seconds }) => {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  useEffect(() => {
    const stopwatchOffset = new Date();

    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + wait_seconds);

    reset(stopwatchOffset);
  }, [wait_seconds]);

  return hours === 0 ? (
    <div
      style={{
        fontSize: "1.4em",
        fontWeight: "bold",
        background: "#ededed",
        padding: "4px 12px",
        borderRadius: "4px",
      }}
    >
      {("0" + minutes).slice(-2)}:{("0" + seconds).slice(-2)}
    </div>
  ) : (
    <div
      style={{
        fontSize: "1.4em",
        fontWeight: "bold",
        background: "#ededed",
        padding: "4px 12px",
        borderRadius: "4px",
      }}
    >
      {("0" + hours).slice(-2)}:{("0" + minutes).slice(-2)}:
      {("0" + seconds).slice(-2)}
    </div>
  );
};

export default WaitCounter;
