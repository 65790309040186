import React, { useCallback, useEffect, useState } from "react";

import {
  Form,
  Input,
  FormField,
  Button,
  Checkbox,
  Dropdown,
} from "semantic-ui-react";

const Cards = ({ cards = [], onAddCard, onCardSelected }) => {
  const [card, setCard] = useState();

  useEffect(() => {
    if (cards.length === 0) {
      return;
    }

    const defaultCard = cards.find((c) => c.default);

    if (defaultCard) {
      setCard(defaultCard);
    } else {
      setCard(cards[0]);
    }
  }, [cards]);

  useEffect(() => {
    if (!card) {
      return;
    }

    onCardSelected(card.source_id);
  }, [card]);

  if (!card) {
    return null;
  }

  return (
    <div>
      <Dropdown
        fluid
        selection
        value={card.value}
        options={cards}
        onChange={(event, data) => {
          const record = cards.find((p) => p.value === data.value);

          setCard(record);
        }}
      />

      <div
        style={{
          marginTop: 10,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <span
          style={{
            fontSize: "0.8em",
            textDecoration: "underline",
            color: "black",
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClick={onAddCard}
        >
          Add Card
        </span>
      </div>

      <Form>
        <FormField
          disabled
          control={Input}
          label="Card Number"
          value={`**** **** **** ${card.last4}`}
        />

        <FormField
          disabled
          control={Input}
          label="Expiration Date"
          value={`${card.exp_month}/${card.exp_year}`}
        />
      </Form>
    </div>
  );
};

export default Cards;
