import { Cookies } from "react-cookie";

import config from "app/config";

const date = new Date();
const daysToExpire = 360;

date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);

const cookies = new Cookies();
const cookie_options = {
  path: "/",
  expires: date,
  secure: config.env === "production",
  domain: config.cookie_domain,
};

export function setCookie(key, value) {
  return cookies.set(key, value, cookie_options);
}

export function getCookie(key) {
  return cookies.get(key);
}

export function removeCookie(key) {
  return cookies.remove(key, cookie_options);
}
