import React, { useEffect, useState, useCallback } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Form, Button, Checkbox } from "semantic-ui-react";

import Cards from "./cards";

import { toastError } from "app/utils/toast";
import { handleApiError } from "app/utils/errors";
import { useGlobal } from "app/services/global/context";
import { ValidationsContext } from "app/services/validations/context";

const CheckoutForm = ({
  saveCard = false,
  clientSecret,
  returnUrl,
  handlingPayment,
  onValidations,
  onSaveCard,
  onStartPayment,
  onFinishPayment,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const { onFetchCards } = useGlobal();

  const [cards, setCards] = useState([]);
  const [addCard, setAddCard] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCards = useCallback(() => {
    const requestAsync = async () => {
      try {
        const response = await onFetchCards();

        const { cards } = response.data;

        setCards(cards);

        setAddCard(cards.length === 0);
      } catch (e) {
        console.log(e);
      }
    };

    requestAsync();
  }, []);

  //   useEffect(() => {
  //     if (!stripe) {
  //       return;
  //     }

  //     const clientSecret = new URLSearchParams(window.location.search).get(
  //       "payment_intent_client_secret"
  //     );

  //     if (!clientSecret) {
  //       return;
  //     }

  //     stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //       switch (paymentIntent.status) {
  //         case "succeeded":
  //           setMessage("Payment succeeded!");
  //           break;
  //         case "processing":
  //           setMessage("Your payment is processing.");
  //           break;
  //         case "requires_payment_method":
  //           setMessage("Your payment was not successful, please try again.");
  //           break;
  //         default:
  //           setMessage("Something went wrong.");
  //           break;
  //       }
  //     });
  //   }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,

      // confirmParams: {
      //   // Make sure to change this to your payment completion page
      //   return_url: "http://localhost:4000",
      // },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const handlePayment = async () => {
    try {
      let response;

      await onStartPayment();

      if (addCard) {
        await elements.submit();

        response = await stripe.confirmPayment({
          elements,
          // redirect: "if_required",
          clientSecret,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: returnUrl,
          },
        });
      } else {
        response = await stripe.confirmPayment({
          // elements,
          // redirect: "if_required",
          clientSecret,
          confirmParams: {
            payment_method: paymentMethod,
            // Make sure to change this to your payment completion page
            return_url: returnUrl,
          },
        });
      }

      const { error } = response;

      if (!error) {
        return;
      }

      if (error.type === "card_error" || error.type === "validation_error") {
        toastError(error.message);
      } else {
        toastError("An unexpected error occurred.");
      }
    } catch (e) {
      handleApiError(e, onValidations);
    } finally {
      onFinishPayment();
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      {cards.length > 0 && !addCard ? (
        <Cards
          cards={cards}
          onAddCard={() => setAddCard(true)}
          onCardSelected={(source_id) => setPaymentMethod(source_id)}
        />
      ) : (
        <PaymentElement
          id="payment-element"
          options={{
            layout: "tabs",
          }}
        />
      )}

      {(cards.length === 0 || addCard) && (
        <Checkbox
          size="huge"
          checked={saveCard}
          label="Remember card for future use"
          style={{
            marginTop: 20,
            fontSize: "1em",
          }}
          onChange={(event, data) => {
            onSaveCard(!saveCard);
          }}
        />
      )}

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row-reverse",
        }}
      >
        <Button
          color="black"
          loading={handlingPayment}
          disabled={handlingPayment}
          onClick={handlePayment}
        >
          Pay Now
        </Button>

        {cards.length > 0 && addCard && (
          <Button onClick={() => setAddCard(false)}>Back</Button>
        )}
      </div>
    </div>
  );
};

export default CheckoutForm;
