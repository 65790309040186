import React, { useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";

import {
  Icon,
  Button,
  Popup,
  Label,
  Header,
  Container,
  Message,
  MessageHeader,
  GridColumn,
  Grid,
  GridRow,
  Segment,
  ModalHeader,
  ModalContent,
  ModalActions,
  Modal,
  List,
  ListItem,
  ListContent,
  Form,
  FormGroup,
  FormInput,
  FormField,
} from "semantic-ui-react";
import { Map, Marker, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

import { handleApiError } from "app/utils/errors";
import { useGlobal } from "app/services/global/context";

import Loading from "app/components/loading";
import HourRequest from "app/pages/trips/hour-request";
import WaitCounter from "app/pages/trips/wait_counter";
import ManageHours from "app/pages/trips/manage-hours";
import ManageLocations from "app/pages/trips/manage-locations";
import ProcessPayment from "app/pages/trips/process-payment";
import LocationRequest from "app/pages/trips/location-request";

const TRIP_TYPE = {
  one_time: "One time",
  hourly: "Hourly",
};

const View = () => {
  const map = useMap("map");
  const routesLibrary = useMapsLibrary("routes");

  const navigate = useNavigate();
  const { trip_id } = useParams();

  const {
    user,
    stripe,
    loaded,
    klassOptions,
    authenticated,
    onGetTrip,
    onFetchDrivers,
    onUpdateAdminTrip,
    onFetchPassengers,
    onConfirmTrip,
    onCancelTrip,
    onDeclineTrip,

    onStartedTrip,
    onArrivedTrip,
    onPickUpCustomerTrip,
    onWaitingTrip,
    onFinishTrip,

    onGetHourRequest,
    onCreateHourRequest,
    onGetLocationRequest,
    onCreateLocationRequest,

    onPrepareProcessPayment,
    onSettleProcessPayment,
  } = useGlobal();

  const [trip, setTrip] = useState();
  const [locations, setLocations] = useState([]);
  const [klassDisplay, setKlassDisplay] = useState();
  const [note, setNote] = useState("");
  const [tripWait, setTripWait] = useState();
  const [waitCounter, setWaitCounter] = useState(0);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [setupIntent, setSetupIntent] = useState();
  const [openDriverModal, setOpenDriverModal] = useState(false);
  const [openHoursModal, setOpenHoursModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openTripCancelModal, setOpenTripCancelModal] = useState(false);
  const [openTripDeclineModal, setOpenTripDeclineModal] = useState(false);
  const [openTripFinishModal, setOpenTripFinishModal] = useState(false);

  const [driverIds, setDriverIds] = useState([]);
  const [sharedInPercent, setSharedInPercent] = useState(0);
  const [sharedInAmount, setSharedInAmount] = useState(0);

  const [directionsService, setDirectionsService] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState();
  const [routes, setRoutes] = useState([]);

  const [pickupLocation, setPickupLocation] = useState();
  const [passenger, setPassenger] = useState({
    id: null,
    email: "",
    phone: "",
  });

  const [driverOptions, setDriverOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [validations, setValidations] = useState({});

  const getTrip = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onGetTrip(trip_id);

        const { trip } = response.data;

        const pickup = trip.locations.find(
          (location) => location.type === "pickup"
        );

        setPickupLocation(pickup);

        setTrip(trip);
        setNote(trip.note);

        setInitialized(true);
        setLoading(false);
      } catch (e) {
        handleApiError(e);

        navigate("/");
      }
    };

    requestAsync();
  }, [trip_id]);

  const processRouteChanges = useCallback(() => {
    const requestAsync = async () => {
      try {
        let waypoints = [];
        let destination;

        const pickupLocation = locations.find(
          (location) => location.type === "pickup"
        );

        const dropoffLocation = locations.find(
          (location) => location.type === "dropoff"
        );

        const stopLocations = locations.filter(
          (location) => location.type === "stop"
        );

        if (!pickupLocation) {
          return;
        }

        const origin = `${pickupLocation.latitude},${pickupLocation.longitude}`;
        const lastStop = stopLocations[stopLocations.length - 1];

        if (dropoffLocation) {
          destination = `${dropoffLocation.latitude},${dropoffLocation.longitude}`;
          waypoints = stopLocations.map((l) => ({
            stopover: true,
            location: `${l.latitude},${l.longitude}`,
          }));
        } else if (lastStop && lastStop.name) {
          destination = `${lastStop.latitude},${lastStop.longitude}`;
          waypoints = stopLocations
            .filter((l) => l.name !== lastStop.name)
            .map((l) => ({
              stopover: true,
              location: `${l.latitude},${l.longitude}`,
            }));
        }

        if (!origin || !destination) {
          return;
        }

        const response = await directionsService.route({
          origin,
          waypoints,
          destination,
          // Google will mix waypoints
          // Make optimizeWaypoints false
          optimizeWaypoints: false,
          travelMode: "DRIVING", //"WALKING",
          // provideRouteAlternatives: true,
        });

        directionsRenderer.setDirections(response);

        setRoutes(response.routes);

        console.log("roaute are changed", response.routes);
      } catch (e) {
        console.log(e);
      }
    };

    requestAsync();
  }, [locations, directionsService, directionsRenderer]);

  const createHourRequest = useCallback(
    (data) => {
      const requestAsync = async () => {
        try {
          await onCreateHourRequest({
            trip_id: trip.id,
            hour_request: data,
          });

          getTrip();
          setOpenHoursModal(false);
        } catch (e) {
          handleApiError(e);
        }
      };

      requestAsync();
    },
    [trip]
  );

  const createLocationRequest = useCallback(
    (data) => {
      const requestAsync = async () => {
        try {
          await onCreateLocationRequest({
            trip_id: trip.id,
            location_request: data,
          });

          getTrip();
          setOpenLocationModal(false);
        } catch (e) {
          handleApiError(e);
        }
      };

      requestAsync();
    },
    [trip]
  );

  const updateAdminTrip = useCallback(
    (data) => {
      const requestAsync = async () => {
        try {
          const response = await onUpdateAdminTrip(trip.id, {
            trip: data,
          });

          setTrip(response.data.trip);

          setOpenDriverModal(false);

          setValidations({});
        } catch (e) {
          handleApiError(e, (validations) => {
            setValidations(validations);
          });
        }
      };

      requestAsync();
    },
    [trip]
  );

  const fetchDrivers = useCallback(() => {
    const requestAsync = async () => {
      try {
        const response = await onFetchDrivers();

        const { drivers } = response.data;

        setDriverOptions(drivers);
      } catch (e) {
        handleApiError(e);
      }
    };

    requestAsync();
  }, [trip]);

  const confirmTrip = useCallback((trip_id) => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onConfirmTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, []);

  const cancelTrip = useCallback((trip_id) => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onCancelTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);

        setOpenTripCancelModal(false);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, []);

  const declineTrip = useCallback((trip_id) => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onDeclineTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);

        setOpenTripDeclineModal(false);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, []);

  const startTrip = useCallback((trip_id) => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onStartedTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, []);

  const arrivedTrip = useCallback((trip_id) => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onArrivedTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, []);

  const pickedUpCustomerTrip = useCallback((trip_id) => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onPickUpCustomerTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, []);

  const waitingTrip = useCallback((trip_id) => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onWaitingTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, []);

  const finishTrip = useCallback((trip_id) => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onFinishTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);

        setOpenTripFinishModal(false);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, []);

  const prepareProcessPayment = useCallback(
    (id) => {
      const requestAsync = async () => {
        try {
          await onPrepareProcessPayment(id);

          getTrip();
        } catch (e) {
          handleApiError(e);
        }
      };

      requestAsync();
    },
    [trip]
  );

  const settleProcessPayment = useCallback(
    (id) => {
      const requestAsync = async () => {
        try {
          await onSettleProcessPayment(id);

          getTrip();
        } catch (e) {
          handleApiError(e);
        }
      };

      requestAsync();
    },
    [trip]
  );

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    getTrip();

    if (user.super_admin) {
      fetchDrivers();
    }
  }, [authenticated, user]);

  useEffect(() => {
    if (!trip) {
      return;
    }

    const { driver_ids, locations, shared_in_percent } = trip;

    const pickup = locations.find((l) => l.type === "pickup");

    setLocations(locations);
    setPickupLocation(pickup);

    setDriverIds(driver_ids);
    setSharedInPercent(shared_in_percent);

    const tripWait = trip.trip_waits.find((tw) => tw.handled === false);

    setTripWait(tripWait);
  }, [trip]);

  useEffect(() => {
    if (!trip) {
      return;
    }

    const { total_in_cents } = trip;

    const amount = (total_in_cents / 100) * (sharedInPercent / 100);
    const sharedAmount = Math.floor(amount * 100) / 100;

    setSharedInAmount(sharedAmount);
  }, [trip, sharedInPercent]);

  useEffect(() => {
    setValidations({});
  }, [passenger]);

  useEffect(() => {
    if (!trip || klassOptions.length === 0) {
      return;
    }

    const { klass } = trip;

    const record = klassOptions.find((k) => k.value === klass);

    setKlassDisplay(record.text);
  }, [trip, klassOptions]);

  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
  }, [routesLibrary, map]);

  // Use directions service
  useEffect(() => {
    if (!directionsService || !directionsRenderer) {
      return;
    }

    processRouteChanges();
  }, [locations, directionsService, directionsRenderer]);

  if (loaded && !authenticated) {
    navigate("/");

    return;
  }

  if (!trip || !user) {
    return (
      <Container style={{ marginTop: 100 }}>
        <Header as="h1">Checkout</Header>
        <Loading />
      </Container>
    );
  }

  const {
    id,
    state,
    user_id,
    drivers,
    charges,
    passengers,
    cancellable,
    action_state,
    trip_hours,
    payment_state,
    process_payment,
    hour_requests,
    location_requests,
    prepare_charges_allowed,
    settle_charges_allowed,
  } = trip;
  const { account_driver, super_admin, account_owner } = user;

  return (
    <Container style={{ marginBottom: 200 }}>
      <Header as="h1">Trip Details</Header>

      <Message style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ fontWeight: "bold", fontSize: "1.3em" }}>
          {trip.number}
        </div>
        <div style={{ fontWeight: "bold", fontSize: "1.5em" }}>
          {trip.amount_in_currency}
        </div>
      </Message>

      {payment_state === "payment_pending" && (
        <Message negative>
          <MessageHeader
            style={{
              fontWeight: "bold",
              fontSize: "1.3em",
              textAlign: "center",
            }}
          >
            Payment for the trip is pending
          </MessageHeader>
        </Message>
      )}

      <Grid stackable columns={2}>
        <GridRow>
          <GridColumn style={{}}>
            <Segment padded>
              <Label attached="top">Pickup Date</Label>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Header
                  as="h3"
                  style={{ marginTop: 0, marginBottom: 0 }}
                  content={`${trip.pickup_date}, ${trip.pickup_time}`}
                />

                {state === "pending" && (
                  <Button basic compact color="grey">
                    PENDING
                  </Button>
                )}

                {state === "booked" && (
                  <Button basic compact color="black">
                    BOOKED
                  </Button>
                )}

                {state === "confirmed" && (
                  <Button basic compact color="black">
                    CONFIRMED
                  </Button>
                )}

                {state === "finished" && (
                  <Button compact color="teal">
                    FINISHED
                  </Button>
                )}

                {state === "cancelled" && (
                  <Button basic compact color="red">
                    CANCELLED
                  </Button>
                )}

                {state === "declined" && (
                  <Button basic compact color="red">
                    DECLINED
                  </Button>
                )}
              </div>
            </Segment>

            <Segment padded>
              <Label attached="top">Passenger</Label>

              {passengers.map((passenger) => {
                return (
                  <div key={passenger.id}>
                    <Header
                      as="h3"
                      style={{ marginTop: 0 }}
                      content={`${passenger.full_name}`}
                    />
                    <Header
                      as="h3"
                      style={{ margin: 0 }}
                      content={`${passenger.email},    ${passenger.phone}`}
                    />
                  </div>
                );
              })}
            </Segment>

            <Segment style={{ height: 400 }}>
              <Label attached="top">Map</Label>

              <div
                style={{
                  width: "100%",
                  height: "94%",
                }}
              >
                <Map
                  id="map"
                  defaultZoom={12}
                  defaultCenter={
                    pickupLocation
                      ? {
                          lat: pickupLocation.latitude,
                          lng: pickupLocation.longitude,
                        }
                      : {
                          lat: 37.7749,
                          lng: -122.4194,
                        }
                  }
                  disableDefaultUI={true}
                  style={{ borderRadius: 80, backgroundColor: "white" }}
                >
                  {pickupLocation && locations.length === 1 && (
                    <Marker
                      position={{
                        lat: pickupLocation.latitude,
                        lng: pickupLocation.longitude,
                      }}
                    />
                  )}
                </Map>
              </div>
            </Segment>
          </GridColumn>
          <GridColumn>
            <Segment padded>
              <Label attached="top">Car</Label>

              <div
                style={{
                  marginBottom: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        marginRight: 24,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "1.4em" }}>{klassDisplay}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 12,
                      }}
                    >
                      <Icon name="users" size="large" />
                      <span style={{ fontSize: "1.4em" }}>
                        {trip.number_of_passengers}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon name="suitcase" size="large" />
                      <span style={{ fontSize: "1.4em" }}>
                        {trip.number_of_luggages}
                      </span>
                    </div>
                  </div>
                </div>

                {drivers.map((driver) => {
                  return (
                    <div
                      style={{
                        fontSize: "1.4em",
                        marginTop: 20,
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>Driver</span> -{" "}
                      {driver.text}
                    </div>
                  );
                })}
              </div>

              {super_admin && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    color="black"
                    disabled={state == "cancelled" || state == "declined"}
                    onClick={() => setOpenDriverModal(true)}
                  >
                    Add Driver
                  </Button>
                </div>
              )}
            </Segment>

            <Segment padded>
              <Label attached="top">Ride</Label>

              <Header
                as="h3"
                style={{ marginTop: 0 }}
                content={`${TRIP_TYPE[trip.type]}`}
              />

              {trip.type === "hourly" && (
                <Header
                  as="h3"
                  style={{ marginTop: 0 }}
                  content={`Reserved for ${trip.period} hours`}
                  subheader={"   20 km per hour included"}
                />
              )}

              <Header
                as="h3"
                style={{ marginTop: 0 }}
                content={`Estimated ${trip.distance} km, ${trip.duration} min`}
              />

              {(super_admin || account_driver) && trip.type === "hourly" && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    color="black"
                    disabled={state == "cancelled" || state == "declined"}
                    onClick={() => setOpenHoursModal(true)}
                  >
                    Add Hours
                  </Button>
                </div>
              )}
            </Segment>

            <Segment padded>
              <Label attached="top">Locations</Label>

              {trip.locations.map((location) => {
                if (location.type === "pickup") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginBottom: 8,
                      }}
                    >
                      <Icon
                        name="map marker alternate"
                        style={{ fontSize: "1.4em" }}
                      />
                      <Header
                        as="h3"
                        style={{ marginTop: 0 }}
                        content={`${location.name}`}
                      />

                      <Popup
                        basic
                        trigger={
                          <Icon
                            name="copy outline"
                            style={{
                              marginLeft: 4,
                              fontSize: "1.4em",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              navigator.clipboard.writeText(location.name)
                            }
                          />
                        }
                        content="Copy Location"
                      />
                    </div>
                  );
                } else if (location.type === "stop") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        paddingLeft: 10,
                        marginBottom: 8,
                      }}
                    >
                      <Icon name="stop circle" style={{ fontSize: "1.2em" }} />
                      <Header
                        as="h3"
                        style={{ marginTop: 0 }}
                        content={`${location.name}`}
                      />
                      <Icon
                        name="copy outline"
                        style={{
                          marginLeft: 4,
                          fontSize: "1.4em",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigator.clipboard.writeText(location.name)
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginBottom: 8,
                      }}
                    >
                      <Icon name="map pin" style={{ fontSize: "1.4em" }} />

                      <Header
                        as="h3"
                        style={{ marginTop: 0 }}
                        content={`${location.name}`}
                      />
                      <Icon
                        name="copy outline"
                        style={{
                          marginLeft: 4,
                          fontSize: "1.4em",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigator.clipboard.writeText(location.name)
                        }
                      />
                    </div>
                  );
                }
              })}

              {(super_admin || account_driver) && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    color="black"
                    disabled={state == "cancelled" || state == "declined"}
                    onClick={() => setOpenLocationModal(true)}
                  >
                    Add Stops
                  </Button>
                </div>
              )}
            </Segment>

            <Segment padded>
              <Label attached="top">Notes</Label>

              <Header
                as="h3"
                style={{ margin: 0, whiteSpace: "pre-line", lineHeight: 1 }}
                content={trip.note || "No Notes"}
              />
            </Segment>

            {tripWait && (
              <Segment padded>
                <Label attached="top">Wait Time</Label>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Header
                    as="h3"
                    style={{ margin: 0 }}
                    content={`Free wait time: ${tripWait.free_time_display}`}
                  />

                  <WaitCounter wait_seconds={tripWait.current_wait_seconds} />
                </div>
              </Segment>
            )}
          </GridColumn>
        </GridRow>
      </Grid>

      {!account_driver && (
        <Grid stackable columns={1}>
          <GridRow>
            <GridColumn>
              <Segment padded>
                <Label attached="top">Payments</Label>

                <List divided verticalAlign="middle">
                  {charges.map((charge, index) => {
                    const extraStyles =
                      index > 0
                        ? {
                            borderTop: "1px solid #c1c1c1",
                            marginTop: "10px",
                            paddingTop: "10px",
                            alignItems: "center",
                          }
                        : {};

                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          ...extraStyles,
                        }}
                      >
                        <div>
                          <Header
                            as="h3"
                            style={{ marginTop: 0, marginBottom: 0 }}
                            content={`${charge.amount_in_currency}`}
                          />

                          {charge.comment}
                        </div>

                        {charge.state === "pending" && (
                          <Button compact color="gray">
                            PENDING
                          </Button>
                        )}

                        {charge.state === "payment_method_needed" && (
                          <Button
                            color="pink"
                            disabled={
                              state == "cancelled" || state == "declined"
                            }
                            onClick={() =>
                              navigate(
                                `/trips/${id}/charges/${charge.id}/payment`
                              )
                            }
                          >
                            Make a payment
                          </Button>
                        )}

                        {charge.state === "authorized" && (
                          <Button basic compact color="black">
                            AUTHORIZED
                          </Button>
                        )}

                        {charge.state === "succeeded" && (
                          <Button compact color="teal">
                            PAID
                          </Button>
                        )}

                        {charge.state === "cancelled" && (
                          <Button basic compact color="red">
                            CANCELLED
                          </Button>
                        )}

                        {charge.state === "action_needed" && (
                          <Button
                            compact
                            color="pink"
                            onClick={() => window.open(charge.next_action_url)}
                          >
                            FINISH PAYMENT
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </List>
              </Segment>
            </GridColumn>
          </GridRow>
        </Grid>
      )}

      {(account_driver || super_admin) && hour_requests.length > 0 && (
        <Grid stackable columns={1}>
          <GridRow>
            <GridColumn>
              <Segment padded>
                <Label attached="top">Hours Requested</Label>

                <List divided verticalAlign="middle">
                  {hour_requests.map((request, index) => (
                    <HourRequest
                      index={index}
                      key={request.id}
                      request={request}
                      onSuccess={getTrip}
                      onGetRequest={onGetHourRequest}
                    />
                  ))}
                </List>
              </Segment>
            </GridColumn>
          </GridRow>
        </Grid>
      )}

      {(account_driver || super_admin) && location_requests.length > 0 && (
        <Grid stackable columns={1}>
          <GridRow>
            <GridColumn>
              <Segment padded>
                <Label attached="top">Locations Requested</Label>

                <List divided verticalAlign="middle">
                  {location_requests.map((request, index) => (
                    <LocationRequest
                      index={index}
                      key={request.id}
                      request={request}
                      onSuccess={getTrip}
                      onGetRequest={onGetLocationRequest}
                    />
                  ))}
                </List>
              </Segment>
            </GridColumn>
          </GridRow>
        </Grid>
      )}

      <Grid stackable columns={1}>
        <GridRow>
          <GridColumn style={{ marginBottom: 100 }}>
            <Segment padded>
              <Label attached="top">Actions</Label>

              {((super_admin &&
                (state === "confirmed" || state === "finished")) ||
                (!super_admin && account_driver)) && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <Button
                      size="small"
                      color="black"
                      basic={action_state === "action_pending"}
                      disabled={
                        state === "pending" ||
                        state === "booked" ||
                        action_state === "action_finished"
                      }
                      onClick={() => startTrip(id)}
                    >
                      Start{" "}
                      <Icon
                        name="check"
                        style={{
                          margin: 0,
                          display:
                            action_state !== "action_pending"
                              ? "inline-block"
                              : "none",
                        }}
                      />
                    </Button>

                    <Button
                      basic={
                        action_state === "action_pending" ||
                        action_state === "action_started"
                      }
                      size="small"
                      color="black"
                      disabled={
                        state === "pending" ||
                        state === "booked" ||
                        action_state === "action_pending" ||
                        action_state === "action_finished"
                      }
                      onClick={() => arrivedTrip(id)}
                    >
                      Arrived{" "}
                      <Icon
                        name="check"
                        style={{
                          margin: 0,
                          display:
                            action_state === "action_pending" ||
                            action_state === "action_started"
                              ? "none"
                              : "inline-block",
                        }}
                      />
                    </Button>

                    <Button
                      basic={
                        action_state === "action_pending" ||
                        action_state === "action_started" ||
                        action_state === "action_arrived" ||
                        action_state === "action_waiting"
                      }
                      size="small"
                      color="black"
                      disabled={
                        state === "pending" ||
                        state === "booked" ||
                        action_state === "action_pending" ||
                        action_state === "action_started" ||
                        action_state === "action_finished"
                      }
                      onClick={() => pickedUpCustomerTrip(id)}
                    >
                      Customer In{" "}
                      <Icon
                        name="check"
                        style={{
                          margin: 0,
                          display:
                            action_state === "action_pickup" ||
                            action_state === "action_finished"
                              ? "inline-block"
                              : "none",
                        }}
                      />
                    </Button>

                    <Button
                      basic={
                        action_state === "action_pending" ||
                        action_state === "action_started" ||
                        action_state === "action_arrived" ||
                        action_state === "action_waiting" ||
                        action_state === "action_pickup"
                      }
                      size="small"
                      color="black"
                      disabled={
                        state === "pending" ||
                        state === "booked" ||
                        action_state === "action_pending" ||
                        action_state === "action_started" ||
                        action_state === "action_finished"
                      }
                      onClick={() => setOpenTripFinishModal(true)}
                    >
                      Finished{" "}
                      <Icon
                        name="check"
                        style={{
                          margin: 0,
                          display:
                            action_state === "action_finished"
                              ? "inline-block"
                              : "none",
                        }}
                      />
                    </Button>
                  </div>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {state !== "finished" && (
                      <Button
                        basic={action_state !== "action_waiting"}
                        size="small"
                        color="black"
                        disabled={
                          state === "pending" ||
                          state === "booked" ||
                          action_state === "action_pending" ||
                          action_state === "action_started" ||
                          action_state === "action_finished"
                        }
                        onClick={() => waitingTrip(id)}
                      >
                        Waiting{" "}
                        <Icon
                          name="check"
                          style={{
                            margin: 0,
                            display:
                              action_state === "action_waiting"
                                ? "inline-block"
                                : "none",
                          }}
                        />
                      </Button>
                    )}

                    {state === "finished" && super_admin && (
                      <ProcessPayment
                        trip={trip}
                        getTrip={getTrip}
                        onPrepareFinish={() => {
                          setTrip({
                            ...trip,
                            process_payment: {
                              ...process_payment,
                              state: "generate_charges",
                            },
                          });
                        }}
                        onSettleFinish={() => {
                          setTrip({
                            ...trip,
                            process_payment: {
                              ...process_payment,
                              state: "capture_charges",
                            },
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              )}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {!super_admin && !account_driver && (
                  <Button
                    disabled={
                      !cancellable ||
                      user_id !== user.id ||
                      state === "cancelled" ||
                      state === "declined"
                    }
                    onClick={() => setOpenTripCancelModal(true)}
                  >
                    Cancel
                  </Button>
                )}

                {(state === "pending" ||
                  state === "declined" ||
                  state === "cancelled" ||
                  state === "booked") &&
                  super_admin && (
                    <Button
                      disabled={state === "cancelled" || state === "declined"}
                      onClick={() => setOpenTripDeclineModal(true)}
                    >
                      Decline
                    </Button>
                  )}

                {state === "booked" && super_admin && (
                  <Button color="black" onClick={() => confirmTrip(id)}>
                    Confirm
                  </Button>
                )}
              </div>
            </Segment>
          </GridColumn>
        </GridRow>
      </Grid>

      <Modal
        size={"tiny"}
        open={openDriverModal}
        onClose={() => setOpenDriverModal(false)}
      >
        <ModalHeader>Add Driver</ModalHeader>
        <ModalContent>
          <Form>
            <Form.Dropdown
              fluid
              selection
              multiple
              value={driverIds}
              options={driverOptions}
              placeholder="Select Driver"
              onChange={(event, data) => setDriverIds(data.value)}
            />

            <FormGroup widths="equal">
              <FormField>
                <FormInput
                  fluid
                  icon="percent"
                  iconPosition="left"
                  value={sharedInPercent}
                  label="Drivers Share in Percent"
                  placeholder="Enter Percent"
                  error={validations.shared_in_percent}
                  onChange={(event, data) => setSharedInPercent(data.value)}
                />
              </FormField>

              <FormField>
                <FormInput
                  fluid
                  icon="money"
                  iconPosition="left"
                  value={sharedInAmount}
                  label="Drivers Share in Amount"
                  placeholder="Enter Amount"
                />
              </FormField>
            </FormGroup>
          </Form>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => setOpenDriverModal(false)}>Cancel</Button>
          <Button
            color="black"
            onClick={() =>
              updateAdminTrip({
                driver_ids: driverIds,
                shared_in_percent: sharedInPercent,
              })
            }
          >
            Save
          </Button>
        </ModalActions>
      </Modal>

      <Modal
        size={"tiny"}
        open={openTripFinishModal}
        onClose={() => setOpenTripFinishModal(false)}
      >
        <ModalHeader>Finish Trip</ModalHeader>
        <ModalContent>
          <Header as="h3">Are you sure you want to finish the trip?</Header>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => setOpenTripFinishModal(false)}>No</Button>
          <Button color="black" onClick={() => finishTrip(id)}>
            Yes
          </Button>
        </ModalActions>
      </Modal>

      <Modal
        size={"tiny"}
        open={openTripCancelModal}
        onClose={() => setOpenTripCancelModal(false)}
      >
        <ModalHeader>Cancel Trip</ModalHeader>
        <ModalContent>
          <Header as="h3">Are you sure you want to cancel the trip?</Header>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => setOpenTripCancelModal(false)}>No</Button>
          <Button color="black" onClick={() => cancelTrip(id)}>
            Yes
          </Button>
        </ModalActions>
      </Modal>

      <Modal
        size={"tiny"}
        open={openTripDeclineModal}
        onClose={() => setOpenTripDeclineModal(false)}
      >
        <ModalHeader>Decline Trip</ModalHeader>
        <ModalContent>
          <Header as="h3">Are you sure you want to decline the trip?</Header>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => setOpenTripDeclineModal(false)}>No</Button>
          <Button color="black" onClick={() => declineTrip(id)}>
            Yes
          </Button>
        </ModalActions>
      </Modal>

      {openHoursModal && (
        <ManageHours
          onSave={createHourRequest}
          onClose={() => setOpenHoursModal(false)}
        />
      )}

      {openLocationModal && (
        <ManageLocations
          trip={trip}
          onSave={createLocationRequest}
          onClose={() => setOpenLocationModal(false)}
        />
      )}
    </Container>
  );
};

export default View;
