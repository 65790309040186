import React, { useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import {
  Form,
  Loader,
  Dimmer,
  FormField,
  Header,
  Container,
  Message,
  GridColumn,
  Grid,
  GridRow,
  Segment,
  Dropdown,
  TextArea,
} from "semantic-ui-react";
import { Elements } from "@stripe/react-stripe-js";

import config from "app/config";
import { handleApiError } from "app/utils/errors";
import { useGlobal } from "app/services/global/context";

import Loading from "app/components/loading";
import Checkout from "app/pages/trips/checkout";
import PassengerForm from "app/pages/passengers/screen/form";
import FormPhoneInput from "app/components/form-phone-input";

const TripPayment = () => {
  const navigate = useNavigate();
  const { trip_id, charge_id } = useParams();

  const {
    user,
    stripe,
    loaded,
    authenticated,
    onGetTrip,
    onGetCharge,
    onUpdateCharge,
    onUpdateTrip,
    onCreateSetupIntent,
    onFetchPassengers,
    onCreatePassenger,
    onUpdatePassenger,
    onCreateChargePaymentIntent,
    onCreateTripPassengers,
  } = useGlobal();

  const [trip, setTrip] = useState();
  const [note, setNote] = useState("");
  const [charge, setCharge] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [setupIntent, setSetupIntent] = useState();

  const [pickupLocation, setPickupLocation] = useState();
  const [dropoffLocation, setDropoffLocation] = useState();
  const [passenger, setPassenger] = useState({
    id: null,
    email: "",
    phone: "",
  });

  const [passengerId, setPassengerId] = useState();

  const [saveCard, setSaveCard] = useState(false);
  const [passengers, setPassengers] = useState([]);
  const [handlingPayment, setHandlingPayment] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [validations, setValidations] = useState({});

  const [bookedUrl, setBookedUrl] = useState(
    `/trips/${trip_id}/charges/${charge_id}/booked`
  );
  const [returnUrl, setReturnUrl] = useState(
    `${config.app_url}/trips/${trip_id}/charges/${charge_id}/booked`
  );

  const getTrip = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onGetTrip(trip_id);

        const { trip } = response.data;

        setTrip(trip);
        setNote(trip.note);

        setInitialized(true);
        setLoading(false);
      } catch (e) {
        handleApiError(e);

        navigate("/");
      }
    };

    requestAsync();
  }, [trip_id]);

  const getCharge = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onGetCharge(charge_id);

        const { charge } = response.data;

        setCharge(charge);
      } catch (e) {
        handleApiError(e);
      }
    };

    requestAsync();
  }, [charge_id]);

  const onSaveCard = useCallback(
    (save_card) => {
      const requestAsync = async () => {
        try {
          const response = await onUpdateCharge(charge_id, {
            charge: { save_card },
          });

          const { charge } = response.data;

          setCharge(charge);
        } catch (e) {
          handleApiError(e);
        }
      };

      requestAsync();
    },
    [charge_id]
  );

  const updateTripPassenger = useCallback(
    (id) => {
      const requestAsync = async () => {
        try {
          setPassengerId(id);

          onCreateTripPassengers(trip.id, {
            trip: { passenger_ids: [id] },
          });
        } catch (e) {}
      };

      requestAsync();
    },
    [trip]
  );

  const fetchPassengers = useCallback(() => {
    const requestAsync = async () => {
      try {
        const response = await onFetchPassengers();

        const { passengers } = response.data;

        if (passengers.length > 0) {
          setPassengers(passengers);
        } else {
          setPassenger({
            email: user.email,
            phone: user.phone,
            full_name: user.full_name,
          });
          setPassengers([
            {
              email: user.email,
              phone: user.phone,
              key: user.full_name,
              text: user.full_name,
              value: user.full_name,
              full_name: user.full_name,
            },
          ]);
        }
      } catch (e) {
        handleApiError(e);
      }
    };

    requestAsync();
  }, [user]);

  const createChargePaymentIntent = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onCreateChargePaymentIntent(charge_id);

        const { payment_intent } = response.data;

        setPaymentIntent(payment_intent);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, [charge_id]);

  const createSetupIntent = useCallback(() => {
    const requestAsync = async () => {
      try {
        const response = await onCreateSetupIntent();

        const { setup_intent } = response.data;

        setSetupIntent(setup_intent);
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      }
    };

    requestAsync();
  }, []);

  const onSubmit = useCallback(() => {
    const requestAsync = async () => {
      try {
        setHandlingPayment(true);

        let passengerId = passenger.id;

        if (passenger.id) {
          await onUpdatePassenger(passenger.id, {
            passenger: {
              email: passenger.email,
              phone: passenger.phone,
              full_name: passenger.full_name,
            },
          });
        } else {
          const response = await onCreatePassenger({
            passenger: {
              email: passenger.email,
              phone: passenger.phone,
              full_name: passenger.full_name,
            },
          });

          passengerId = response.data.passenger.id;
        }

        const response = await onUpdateTrip(trip_id, {
          trip: {
            note,
            passenger_ids: [passengerId],
          },
        });

        // const { setup_intent } = response.data;

        // setSetupIntent(setup_intent);
      } catch (e) {
        handleApiError(e, (validations) => {
          console.log("Vali: ", validations);
          setValidations(validations);
        });
      } finally {
        setHandlingPayment(false);
      }
    };

    requestAsync();
  }, [note, passenger, validations]);

  const onStartPayment = useCallback(() => {
    const requestAsync = async () => {
      setHandlingPayment(true);

      let passengerId = passenger.id;

      if (passenger.id) {
        await onUpdatePassenger(passenger.id, {
          passenger: {
            email: passenger.email,
            phone: passenger.phone,
            full_name: passenger.full_name,
          },
        });
      } else {
        const response = await onCreatePassenger({
          passenger: {
            email: passenger.email,
            phone: passenger.phone,
            full_name: passenger.full_name,
          },
        });

        passengerId = response.data.passenger.id;
      }

      await onUpdateTrip(trip_id, {
        trip: {
          note,
          passenger_ids: [passengerId],
        },
      });
    };

    return requestAsync();
  }, [note, passenger]);

  const onFinishPayment = useCallback(() => {
    const requestAsync = async () => {
      setHandlingPayment(false);
    };

    requestAsync();
  }, [note, passenger]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    getTrip();
    getCharge();
    createChargePaymentIntent();
  }, [authenticated]);

  useEffect(() => {
    if (!trip) {
      return;
    }

    fetchPassengers();

    const { locations, passenger_ids } = trip;

    const pickup = locations.find((l) => l.type === "pickup");

    setPickupLocation(pickup);
    setPassengerId(passenger_ids[0]);
  }, [trip]);

  useEffect(() => {
    if (!charge) {
      return;
    }

    setSaveCard(charge.save_card);
  }, [charge]);

  useEffect(() => {
    setValidations({});
  }, [passenger]);

  useEffect(() => {
    if (passengers.length === 0 || !passengerId) {
      return;
    }

    const passenger = passengers.find((p) => p.id === passengerId);

    setPassenger(passenger);
  }, [passengers, passengerId]);

  if (loaded && !authenticated) {
    navigate("/");

    return;
  }

  if (!trip || !charge || !paymentIntent) {
    return (
      <Container style={{ marginTop: 100 }}>
        <Header as="h1">Checkout</Header>
        <Loading />
      </Container>
    );
  }

  if (
    paymentIntent.state === "succeeded" ||
    paymentIntent.state === "requires_capture"
  ) {
    navigate(bookedUrl);
  }

  return (
    <Container style={{ marginTop: 100, marginBottom: 100 }}>
      <Header as="h1">Checkout</Header>

      <Message style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ fontWeight: "bold", fontSize: "1.3em" }}>
          {pickupLocation && pickupLocation.name}
        </div>
        <div style={{ fontWeight: "bold", fontSize: "1.5em" }}>
          {trip.amount_in_currency}
        </div>
      </Message>

      <Grid stackable columns={2}>
        <GridRow stretched>
          <GridColumn>
            <Segment>
              <Dimmer active={handlingPayment} inverted>
                <Loader content="Loading" />
              </Dimmer>
              <Form size="large">
                <FormField>
                  <Dropdown
                    fluid
                    selection
                    options={passengers}
                    value={passengerId}
                    style={{ marginRight: 4 }}
                    placeholder="Select Passenger"
                    onChange={(event, data) => {
                      updateTripPassenger(data.value);
                    }}
                  />

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.8em",
                        textDecoration: "underline",
                        color: "black",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenForm(true)}
                    >
                      Add Passenger
                    </span>
                  </div>
                </FormField>

                <FormField>
                  <Form.Input
                    fluid
                    icon="mail"
                    iconPosition="left"
                    value={passenger && passenger.email}
                    placeholder="Enter email address"
                    onChange={(event, data) => {
                      setPassenger((passenger) => ({
                        ...passenger,
                        email: data.value,
                      }));
                    }}
                    error={
                      validations.email
                        ? {
                            content: validations.email,
                          }
                        : false
                    }
                  />
                </FormField>

                <FormPhoneInput
                  country={"us"}
                  value={passenger && passenger.phone}
                  onChange={(phone) => {
                    setPassenger((passenger) => ({
                      ...passenger,
                      phone,
                    }));
                  }}
                  hasError={!!validations.phone}
                />

                <FormField>
                  <TextArea
                    value={note}
                    onChange={(event, data) => {
                      setNote(data.value);
                    }}
                    style={{ minHeight: 140 }}
                    placeholder="Any notes driver needs to know..."
                  />
                </FormField>
              </Form>
            </Segment>
          </GridColumn>
          <GridColumn>
            <Segment>
              <Dimmer active={handlingPayment} inverted>
                <Loader content="Loading" />
              </Dimmer>

              <Form size="large" style={{ height: "100%" }}>
                {stripe && paymentIntent && (
                  <Elements
                    stripe={stripe}
                    options={{
                      clientSecret: paymentIntent.client_secret,
                      appearance: {
                        theme: "stripe",
                      },
                    }}
                  >
                    <Checkout
                      saveCard={saveCard}
                      returnUrl={returnUrl}
                      clientSecret={paymentIntent.client_secret}
                      handlingPayment={handlingPayment}
                      onStartPayment={onStartPayment}
                      onFinishPayment={onFinishPayment}
                      onValidations={(validations) =>
                        setValidations(validations)
                      }
                      onSaveCard={onSaveCard}
                    />
                  </Elements>
                )}
              </Form>
            </Segment>
          </GridColumn>
        </GridRow>
      </Grid>

      <PassengerForm
        open={openForm}
        onSuccess={(passenger) => {
          fetchPassengers();
          setPassenger(passenger);
          setOpenForm(false);
        }}
        onClose={() => setOpenForm(false)}
      />
    </Container>
  );
};

export default TripPayment;

{
  /* <div className="stripe">
<Elements options={options} stripe={stripePromise}>
  <Checkout />
</Elements>
</div> */
}
