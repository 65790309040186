import React from "react";

import { Container } from "semantic-ui-react";

const Footer = () => (
  <Container
    style={{
      display: "flex",
      justifyContent: "space-between",
      background: "#f0f0f0",
      color: "black",
      padding: 8,
      paddingLeft: 16,
      fontWeight: "bold",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      marginTop: 100,
    }}
  >
    <div style={{ border: 8 }}>LUXTI 2024</div>
    <div style={{ border: 8 }}>
      <a
        href="/"
        style={{
          cursor: "pointer",
          color: "black",
          textDecoration: "underline",
        }}
      >
        Privacy Policy
      </a>
    </div>
  </Container>
);

export default Footer;
