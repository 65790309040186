import React, { useState, useCallback, useEffect } from "react";

import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import {
  Input,
  Modal,
  Form,
  Button,
  FormField,
  ModalHeader,
  ModalActions,
  ModalContent,
} from "semantic-ui-react";
import "react-phone-number-input/style.css";

import { useGlobal } from "app/services/global/context";

import { handleApiError } from "app/utils/errors";

const FormModal = ({ open = false, onClose, onSuccess }) => {
  const { onCreatePassenger } = useGlobal();

  const [phone, setPhone] = useState("");
  const [form, setForm] = useState({
    email: "",
    phone: "",
    full_name: "",
  });

  const [initialized, setInitialized] = useState(false);
  const [validations, setValidations] = useState({});

  const applyChanges = useCallback((changes) => {
    setForm((form) => ({ ...form, ...changes }));
  }, []);

  const createPassenger = useCallback(() => {
    const requestAsync = async () => {
      try {
        setValidations({});

        const response = await onCreatePassenger({
          passenger: form,
        });

        const { passenger } = response.data;

        onSuccess(passenger);
      } catch (e) {
        handleApiError(e, setValidations);
      }
    };

    requestAsync();
  }, [form]);

  useEffect(() => {
    applyChanges({ phone });
  }, [phone]);

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <ModalHeader>Add Passenger</ModalHeader>
      <ModalContent>
        <Form size="large" loading={false}>
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Full Name"
            onChange={(event, data) => applyChanges({ full_name: data.value })}
            error={
              validations.full_name
                ? {
                    content: validations.full_name,
                  }
                : false
            }
          />

          <Form.Input
            fluid
            icon="mail"
            iconPosition="left"
            placeholder="Email Address"
            onChange={(event, data) => applyChanges({ email: data.value })}
            error={
              validations.email
                ? {
                    content: validations.email,
                  }
                : false
            }
          />

          <Form.Input
            className="custom-phone-input"
            input={
              <PhoneInput
                value={phone}
                flags={flags}
                defaultCountry="US"
                placeholder="Enter phone number"
                onChange={setPhone}
                withCountryCallingCode
              />
            }
            error={
              validations.phone
                ? {
                    content: validations.phone,
                  }
                : false
            }
          />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={createPassenger} color="black">
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default FormModal;
