import React, { useState, useEffect, useCallback } from "react";

import { Button } from "semantic-ui-react";

import { handleApiError } from "app/utils/errors";
import { useGlobal } from "app/services/global/context";

const ProcessPayment = ({ trip, getTrip, onPrepareFinish, onSettleFinish }) => {
  const { process_payment } = trip;

  const { onPrepareProcessPayment, onSettleProcessPayment } = useGlobal();

  const [timer, setTimer] = useState();

  const prepareProcessPayment = useCallback(
    (id) => {
      const requestAsync = async () => {
        try {
          await onPrepareProcessPayment(id);

          onPrepareFinish();
        } catch (e) {
          handleApiError(e);
        }
      };

      requestAsync();
    },
    [trip]
  );

  const settleProcessPayment = useCallback(
    (id) => {
      const requestAsync = async () => {
        try {
          await onSettleProcessPayment(id);

          onSettleFinish();
        } catch (e) {
          handleApiError(e);
        }
      };

      requestAsync();
    },
    [trip]
  );

  useEffect(() => {
    if (
      process_payment.state === "generate_charges" ||
      process_payment.state === "generating_charges" ||
      process_payment.state === "capture_charges" ||
      process_payment.state === "capturing_charges"
    ) {
      const timer = setTimeout(() => {
        getTrip();
      }, 5000);

      setTimer(timer);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [process_payment]);

  return (
    <div>
      <Button
        color="black"
        loading={
          process_payment.state === "generate_charges" ||
          process_payment.state === "generating_charges"
        }
        disabled={
          process_payment.state === "generate_charges" ||
          process_payment.state === "generating_charges" ||
          process_payment.state === "capture_charges" ||
          process_payment.state === "capturing_charges"
        }
        onClick={() => prepareProcessPayment(process_payment.id)}
      >
        Prepare Charges
      </Button>

      <Button
        color="black"
        loading={
          process_payment.state === "capture_charges" ||
          process_payment.state === "capturing_charges"
        }
        disabled={
          process_payment.state === "generate_charges" ||
          process_payment.state === "generating_charges" ||
          process_payment.state === "capture_charges" ||
          process_payment.state === "capturing_charges"
        }
        onClick={() => settleProcessPayment(process_payment.id)}
      >
        Process Charges
      </Button>
    </div>
  );
};

export default ProcessPayment;
