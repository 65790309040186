import React, { useState, useEffect } from "react";

import { I18n } from "i18n-js";
import { useSelector, useDispatch } from "react-redux";
import { Header, Container, Accordion, Grid, Icon } from "semantic-ui-react";

import Loading from "app/components/loading";

import { fetchHelpPages } from "../../store/help_pages";

export default function HelpPage(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.me);
  const { help_pages } = useSelector((state) => state.help_pages);

  const { locale } = user;

  const [activeIndex, setActiveIndex] = useState();
  const records = [];
  // const { locale } = useSelector(({ auth }) => auth);
  // const { records, fetching } = useSelector(({ help_pages }) => help_pages);

  useEffect(() => {
    dispatch(fetchHelpPages({ source: "student" }));
  }, [locale]);

  // if (true) return <Loading />;

  return (
    <Container style={{ margin: 20, fontSize: 18 }}>
      <Header as="h2" icon textAlign="center" style={{ marginBottom: 20 }}>
        <Icon
          name="users"
          circular
          style={{ color: "#52c86c", fontSize: 40 }}
        />
        <Header.Content
          style={{
            margin: "25px 0px",
            fontSize: "28px",
            color: "#63666b",
          }}
        >
          {I18n.t("how_can_we_help")}
        </Header.Content>
      </Header>
      <Grid>
        {help_pages.map((help_page) => {
          const { name, sections } = help_page;

          return (
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h1>{name}</h1>
              {sections.map((section, index) => {
                return (
                  <Accordion>
                    <Accordion.Title
                      index={index}
                      active={activeIndex === index}
                      style={{ fontWeight: "bold", color: "rgb(99 102 107)" }}
                      onClick={() => setActiveIndex(index)}
                    >
                      <Icon name="dropdown" />
                      {section.name}
                    </Accordion.Title>
                    <Accordion.Content
                      active={activeIndex === index}
                      style={{
                        padding: "0px 0px 0px 25px",
                        background: "whitesmoke",
                        padding: "20px",
                        boxShadow: "0px 0px 2px #c7c7c7",
                      }}
                    >
                      <p>{section.content}</p>
                    </Accordion.Content>
                  </Accordion>
                );
              })}
            </Grid.Column>
          );
        })}
      </Grid>
    </Container>
  );
}
