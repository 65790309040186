import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FormPhoneInput = ({
  value,
  focus = false,
  country = "us",
  hasError = false,
  onChange,
}) => {
  return (
    <PhoneInput
      value={value}
      country={country}
      dropdownClass="phone-input-dropdown"
      inputStyle={{ paddingLeft: 45 }}
      dropdownStyle={{
        margin: "2px 1px",
      }}
      inputProps={{
        name: "phone",
        required: true,
        autoFocus: focus,
      }}
      containerClass={hasError ? "error" : ""}
      containerStyle={{ padding: 1 }}
      onChange={onChange}
    />
  );
};

export default FormPhoneInput;
