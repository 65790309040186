import React, { useRef, useEffect, useState } from "react";

import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

import { Form, Button } from "semantic-ui-react";

import { getFormattedAddress } from "app/utils/helpers";

const placeOptions = {
  fields: ["geometry", "name", "formatted_address"],
  componentRestrictions: { country: "us" },
};

const SortableItem = ({ item, places, onDelete, onLocationUpdate }) => {
  const ref = useRef();

  const { id } = item;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const [placeStop, setPlaceStop] = useState();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginBottom: 4,
    marginLeft: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  };

  useEffect(() => {
    if (!ref || !places) {
      return;
    }

    setPlaceStop(new places.Autocomplete(ref.current, placeOptions));
  }, [ref, places]);

  useEffect(() => {
    if (!ref) {
      return;
    }

    ref.current.value = item.name;
  }, [ref, item]);

  useEffect(() => {
    if (!placeStop) {
      return;
    }

    placeStop.addListener("place_changed", () => {
      const place = placeStop.getPlace();

      if (place.geometry && place.geometry.location) {
        onLocationUpdate(id, {
          id,
          type: "stop",
          name: getFormattedAddress(place),
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
      }
    });
  }, [id, placeStop]);

  return (
    <div ref={setNodeRef} style={style}>
      <Button basic icon="trash" circular onClick={() => onDelete(id)} />
      <div
        style={{
          flex: 1,
          marginRight: 10,
        }}
      >
        <Form.Input
          fluid
          size="small"
          input={{ ref }}
          icon="map pin"
          iconPosition="left"
          autoComplete="off"
          placeholder="Stop Location"
        />
      </div>

      <Button basic icon="sort" circular {...attributes} {...listeners} />
    </div>
  );
};

export default SortableItem;
