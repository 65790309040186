import React, { useState, useEffect, createRef } from "react";

import { I18n } from "i18n-js";
import ReactPlayer from "react-player";

import { Icon, Modal } from "semantic-ui-react";

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { playVideo, closeVideo } from "app/store/main";

export default function VideoPlayerModal() {
  const dispatch = useDispatch();
  const {
    options: { controls },
    video_src,
  } = useSelector((state) => state.main);

  return (
    <Modal
      open={!!video_src}
      style={{ width: "auto", background: "black" }}
      onClose={() => {
        dispatch(closeVideo());
      }}
    >
      <ReactPlayer
        playing
        className="react-player"
        url={video_src}
        width="auto"
        // controls
        onEnded={() => {
          dispatch(closeVideo());
        }}
        style={{ width: "auto", height: "100%", maxWidth: 600 }}
        controls={!!controls}
      />
    </Modal>
  );
}
