import React, { useState, useEffect } from "react";

import { Container } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Order from "app/pages/main/order";
import LoadingStatus from "app/components/Status";

import { ValidationsContextProvider } from "app/services/validations/context";

export default function Main() {
  const navigate = useNavigate();

  // const {
  //   user: { kid, account_type },
  //   logged_in,
  //   login_in_progress,
  // } = useSelector((state) => state.me);
  // const { initialized } = useSelector((state) => state.reports);

  // useEffect(() => {
  //   if (login_in_progress) return;

  //   if (!logged_in) {
  //     navigate("/login");
  //   }
  // }, [logged_in]);

  // if (!logged_in || login_in_progress) return <LoadingStatus />;

  return (
    <ValidationsContextProvider>
      <Container>
        <Order />
      </Container>
    </ValidationsContextProvider>
  );
}
